// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';

export default BaseModel.extend({

	defaults() {
		return {
			visibility: true,
		};
	},

	initialize() {
		const stars = parseInt(this.get('hotelStars'), 10);

		if (!_.isNaN(stars) && stars > 0) {
			this.set('class', {
				uid: `STARS_${stars}`,
				caption: `STARS_${stars}`,
				icon: 'b-hotel-star',
				repeat: stars,
			});
		} else {
			this.set('class', {
				uid: `STARS_0`,
				caption: `${L10N.get('hotels.noStars')}`,
			});
		}

		const getUniqData = (offers, mapCallback, uniqCallback) => {
			return _.chain(offers)
				.map(_.isFunction(mapCallback) ? mapCallback : ((v) => v))
				.uniq(false, _.isFunction(uniqCallback) ? uniqCallback : ((v) => v))
				.value();
		};
		const address = this.get('address');
		const searchStringArray = [this.get('name')];
		const originCityInfo = this.get('originCityInfo');

		if (address != null) {
			if (address.address) {
				searchStringArray.push(address.address);
			}

			if (address.cityInfo != null && address.city != null) {
				searchStringArray.push(address.address.cityInfo.city.caption);
			}
		}
		if (address != null && originCityInfo != null) {
			const locationHotel = _.pick(address, ['latitude', 'longitude']);
			const locationCity = _.pick(originCityInfo, ['latitude', 'longitude']);

			if (!_.isEmpty(locationHotel) && !_.isEmpty(locationCity)) {
				try {
					const distance = window.google.maps ? Math.ceil(window.google.maps.geometry.spherical.computeDistanceBetween(
						new window.google.maps.LatLng(locationHotel.latitude, locationHotel.longitude),
						new window.google.maps.LatLng(locationCity.latitude, locationCity.longitude),
					)) : NaN;

					const distanceValue = parseFloat((distance / 1000).toFixed(2));
					if (!isNaN(distanceValue)) {
						this.set('distance', distanceValue);
					}
				} catch (e) {
					throw e;
				}
			}
		}

		const searchString = _.compact([searchStringArray]).join(' ').toLowerCase();
		const offers = _.sortBy(this.get('hotelOffers'), (o) => {
			const price_ = o.price && parseInt(o.price.total.amount, 10);
			return (price_ === 0 || isNaN(price_)) ? Number.MAX_SAFE_INTEGER : price_;
		});

		if (!_.isEmpty(offers)) {
			const _offers = _.filter(offers, (o) => {
				const price_ = o.price && parseInt(o.price.total.amount, 10);
				return !(price_ === 0 || isNaN(price_));
			});

			let minPrice = 0;
			let maxPrice = 0;
			if (!_.isEmpty(_offers)) {
				minPrice = _.first(_offers).price.total.amount / (this.get('numberOfNights') || 1);
				maxPrice = _.last(_offers).price.total.amount / (this.get('numberOfNights') || 1);

				this.set('provider', _offers[0].provider);
				this.set('offerDailyAveragePrice', _offers[0].dailyAveragePrice);
				this.set('minPriceObject', _offers[0].price);
				this.set('mintaxInfoList', _offers[0].taxInfoList);
			}
			// TUIBT-1234 ---->
			_.each(offers, (o) => {
				o.tripartiteContract = _.some([o.tripartiteContract, o.provider.uid === 'INTERNAL']);
			});
			const tripartiteContract = _.some(offers, o => o.tripartiteContract === true);
			this.set('tripartiteContract', tripartiteContract);
			// |----- TUIBT-1234

			const mealIncluded = getUniqData(offers, (o) => (o.meal != null && !o.meal.withoutMeal &&
					!['-1', 'nomeal'].includes(o.meal.uid) &&
					!['-', L10N.get('hotels.withoutMeal')].includes(o.meal.caption)));
					
			const mealIsOptional = getUniqData(offers, (o) => ((o.options != null && !_.isEmpty(o.options.meals))));
			const online = getUniqData(offers, (o) => o.online);
			const providers = getUniqData(offers, (o) => o.provider, (el) => el.uid);

			const availability = [];
			const meal = [];

			_.each(_.filter(online, (o) => o != null), (el) => {
				availability.push({
					uid: (el === true) ? 'ONLINE' : 'REQUEST',
					caption: (el === true) ? L10N.get('hotels.online') : L10N.get('hotels.byRequest2'),
				});
			});

			_.each(_.filter(mealIncluded, (o) => o != null), (el) => {
				meal.push({
					uid: (el === true) ? 'WITH_MEAL' : 'WITHOUT_MEAL',
					caption: (el === true) ? L10N.get('filters.withMeal') : L10N.get('filters.withoutMeal'),
				});
			});

			_.each(_.filter(mealIsOptional, (o) => o != null), (el) => {
				if (el === true) {
					meal.push({
						uid: 'OPTIONAL_MEAL',
						caption: L10N.get('filters.optionalMeal'),
					});
				}
			});
			const recommendedTpRule = offers.reduce((result, offer) => {
				if (offer.travelPolicyCompliance && offer.travelPolicyCompliance.rulesApplied) {
					const recommendedRule = offer.travelPolicyCompliance.rulesApplied.find((rule) => rule.action.uid === 'RECOMMENDED');
					if (recommendedRule) {
						result = recommendedRule;
					}
				}
       
				return result;
			}, undefined);

			const travelPolicyCompliance = offers[0].travelPolicyCompliance || {
				rulesApplied: [],
			};
      
			if (travelPolicyCompliance && !travelPolicyCompliance.rulesApplied.find((rule) => rule.action.uid === 'RECOMMENDED')) {
				if (recommendedTpRule) {
					travelPolicyCompliance.rulesApplied.push(recommendedTpRule);
				}
			}
			if (_.isObject(travelPolicyCompliance)) {
				this.set('travelPolicyCompliance', travelPolicyCompliance);

				if (!_.isEmpty(travelPolicyCompliance.rulesApplied)) {
					this.set(`travelPolicyCompliances`, _.map(travelPolicyCompliance.rulesApplied, (rule) => rule.action));
				} else {
					this.set(`travelPolicyCompliances`, {
						caption: L10N.get('travelPolitics.noViolation'),
						uid: 'COMPLIANCE',
					});
				}
			}

			const _subProviders = offers.map((offer) => offer.subProvider);
			const subProviders = _subProviders.filter((item, pos, allSubProviders) => {
				return allSubProviders.indexOf(item) === pos; 
			}).filter((subProvider) => subProvider).map((subProvider) => ({
				uid: subProvider,
				caption: subProvider,
			}));
			this.set('subProvider', subProviders);

			const clientTariffCode = offers.find((offer) => offer.clientTariffCode);
			this.set('clientTariffCode', !!clientTariffCode);

			const recommended = (
				(tripartiteContract === true) || ((stars >= 4) && (offers.length > 5) &&
				(_.find(availability, (el) => el && el.uid === 'ONLINE') !== -1))
			);
			this.set('recommended', recommended);

			this.set('availability', availability);
			this.set('mealIncluded', meal);
			this.set('providers', providers);

			this.set('price', minPrice);
			this.set('minPrice', minPrice);
			this.set('maxPrice', maxPrice);

			this.set('hotelOffers', offers);
		}

		this.set('searchString', searchString);
	},

});
