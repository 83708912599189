module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


var arrRules = arrRules || [];
var showTranslated = showTranslated || false;
if (!_.isEmpty(arrRules)) {
    var lang = STATE.getLanguage();
	var rule16Only = rule16Only || false;
  var fareRules = fareRules || [];
  var fareRuleIndex = fareRuleIndex || 0;
;
__p += '\r\n<div class=\'b-fare-rules__penalty-list\'>\r\n  ';
 if (fareRules.length > 1) { ;
__p += '\r\n    <div class="b-fare-rules-title">' +
((__t = ( L10N.get('bookingForm.selectingTariffRulesTitle') )) == null ? '' : __t) +
'</div>\r\n    <div class="b-fare-rules-buttons">\r\n      ';
 fareRules.forEach((fareRule, _fareRuleIndex) => { ;
__p += '\r\n        <div class="b-fare-rules__button js-open-rule ' +
((__t = ( fareRuleIndex == _fareRuleIndex ? 'active' : '' )) == null ? '' : __t) +
'" data-index="' +
((__t = ( _fareRuleIndex )) == null ? '' : __t) +
'">' +
((__t = ( fareRule.fareBasis )) == null ? '' : __t) +
'</div>\r\n      ';
 }) ;
__p += '\r\n    </div>\r\n  ';
 } ;
__p += '\r\n    ';
 if (!rule16Only) { ;
__p += '\r\n    ' +
((__t = (L10N.get('bookingForm.TranslationWarning'))) == null ? '' : __t) +
'\r\n    <a href="#" class="b-fare-rules__switch-button">\r\n        <h2>\r\n            ' +
((__t = (L10N.get('bookingForm.FullRuleList'))) == null ? '' : __t) +
'\r\n        </h2>\r\n    </a>\r\n\r\n    ';
 } ;
__p += '\r\n    ';
 if (lang === 'ru') { ;
__p += '\r\n        <a href="#" class="b-fare-rules__translate-button">\r\n            <h2>\r\n                ' +
((__t = ( showTranslated ? L10N.get('Common.showOriginal') : L10N.get('Common.translate') )) == null ? '' : __t) +
'\r\n            </h2>\r\n        </a>\r\n    ';
 } ;
__p += '\r\n    ';
 for (var i = 0; i < arrRules.length; i++) {
        var item = arrRules[i];
    if (item && item.number === '16') {
        item.showTranslated = showTranslated;
        item.nr = 0;
    ;
__p += '\r\n    ' +
((__t = ( require('./fareRule.ejs')(item) )) == null ? '' : __t) +
'\r\n    ';

        break;
    }
    } ;
__p += '\r\n</div>\r\n<br/>\r\n';
 if (!rule16Only) { ;
__p += '\r\n<div class="b-fare-rules__full-rules-list" style="display: none">\r\n    ';
 if (lang === 'ru') { ;
__p += '\r\n        <a href="#" class="b-fare-rules__translate-button">\r\n            <h2>\r\n                ' +
((__t = ( showTranslated ? L10N.get('Common.showOriginal') : L10N.get('Common.translate') )) == null ? '' : __t) +
'\r\n            </h2>\r\n        </a>\r\n    ';
 } ;
__p += '\r\n    ';
 var nr = 1;
	for (var i = 0; i < arrRules.length; i++) {
		var item = arrRules[i];
		item.nr = nr;
		item.showTranslated = showTranslated;
		nr++;
    ;
__p += '\r\n    ' +
((__t = ( require('./fareRule.ejs')(item) )) == null ? '' : __t) +
'\r\n    ';
 } ;
__p += '\r\n</div>\r\n';
 } ;
__p += '\r\n';
 } ;
__p += '\r\n\r\n';

}
return __p
}