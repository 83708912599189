module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-offers-train-modal">\n	<div class="b-offers-train-modal__title">\n		';
 if (this.passengers != null) { ;
__p += '\n			' +
((__t = ( L10N.get('trains.toAgreeFor') )) == null ? '' : __t) +
': <span class="b-offers-train-modal__title-person"></span>\n		';
 } else { ;
__p += '\n			' +
((__t = ( L10N.get('trains.toAgree') )) == null ? '' : __t) +
'\n		';
 } ;
__p += '\n	</div>\n	<div class="b-offers-train-modal__list drag-sort-enable">\n		';
 _.each(this.offers, function (offer, i) {
			var train = offer.routeInfo.train;
			var carriage = offer.routeInfo.carriage;
			var seatsRequest = offer.routeInfo.seatsRequest;

			var segments = train.segments;
			var dateDeparture = new Time(segments[0].departureDate);
			var dateArrival = new Time(segments[0].arrivalDate);

			var dateDepartureLocal = segments[0].localDepartureDate ? new Time(segments[0].localDepartureDate) : null;
			var dateArrivalLocal = segments[0].localArrivalDate ? new Time(segments[0].localArrivalDate) : null;

			var totalDurationInMinutes = 0;
			segments.forEach(function(s) {
				totalDurationInMinutes += s.durationInMinutes;
			});


			var minNumber = seatsRequest.range.first;
			var maxNumber = seatsRequest.range.second;
			var numbersString = '';
			if (minNumber != maxNumber) {
				numbersString = L10N.get('trains.seats') + ': <strong>';
				numbersString += minNumber + '&mdash;' + maxNumber;
				numbersString += '</strong>';
			} else {
				numbersString =  L10N.get('trains.seat') + ': <strong>';
				numbersString += minNumber;
				numbersString += '</strong>';
			}

      var seatsMap = carriage.carPlaceData.reduce(function(seatsMap, carPlace) {
        carPlace.availableSeats.forEach(function (place) {
          var currentSeat = parseInt(place, 10);
          if (currentSeat && currentSeat >= minNumber && currentSeat <= maxNumber) {
            var position = carPlace.carPlaceType.caption || 'common';
            if (!seatsMap.hasOwnProperty(position)) {
              seatsMap[position] = [];
            }
            seatsMap[position].push(currentSeat);
          }
        });
        
        return seatsMap;
      }, {});

      var seatsMapKeys = Object.keys(seatsMap);
      var seatsMapTooltip = '<div class="b-offer-seats-tooltip-wrapper">';
        seatsMapKeys.forEach(function (seatsPosition) {
        seatsMapTooltip += '<div><div class="b-offer-seats-tooltip">' + seatsPosition + '</div><div>' + seatsMap[seatsPosition].join(', ') + '</div></div>';
      });
      seatsMapTooltip += '</div>';
      if (seatsMapKeys.length === 0) {
        seatsMapTooltip = "";
      }

      var seatsMapForCopy = '';
      var seatsMapKeys = Object.keys(seatsMap);
      seatsMapKeys.forEach(function (seatsPosition, seatsPositionIndex) {
        seatsMapForCopy += seatsPosition + ': ' + seatsMap[seatsPosition].join(', ') + (seatsPositionIndex < seatsMapKeys.length - 1 ? '\r\n' : '');
      });

      var servicesTooltip = null;
      var carriageServices = (carriage.services || []);
      carriageServices.map(function(service) {
        return service.caption
      }).forEach(function (service, serviceIndex) {
        if (!servicesTooltip) {
          servicesTooltip = '';
        }
        servicesTooltip += service + (serviceIndex < carriageServices.length - 1 ? ', <br/>' : '');
      });

		;
__p += '\n			<div class="b-offers-train-modal__list-item" data-token="' +
((__t = ( offer.token )) == null ? '' : __t) +
'" data-copy-container="' +
((__t = ( i )) == null ? '' : __t) +
'">\n                <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('cabinet.orders.serviceName') )) == null ? '' : __t) +
': ' +
((__t = ( L10N.get('cabinet.orders.serviceTypes.RAIL') )) == null ? '' : __t) +
'</span>\n                <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('trains.train') )) == null ? '' : __t) +
': <span data-replace-newline=" " data-copy-content-from=".b-train-ticket__info-train.b-info-train"></span></span>\n                <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('trains.wagon') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-route__element-seats-carriage-number"></span></span>\n                <span class="js-copy-content copy-content"><span data-copy-content-from=".b-route__element-seats-details"></span></span>\n                <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('hotels.cost') )) == null ? '' : __t) +
': <span data-replace-newline=" " data-copy-content-from=".b-offers-train-modal__list-item-price-container"></span></span>\n                <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('trains.arrivalLocation') )) == null ? '' : __t) +
': <span data-replace-newline=" " data-copy-content-from=".b-route__departure .b-route__terminal"></span> - ' +
((__t = ( L10N.get('trains.arrivalLocation') )) == null ? '' : __t) +
': <span data-replace-newline=" " data-copy-content-from=".b-route__arrival .b-route__terminal"></span></span>\n                <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('transfers.departureDate') )) == null ? '' : __t) +
': <span data-replace-newline=" " data-copy-content-from=".b-route__departure .b-route__date"></span> ' +
((__t = ( L10N.get('trains.localTimeInfo') )) == null ? '' : __t) +
' ';
 if (segments[0].departureMoscowTimeZone === true) { ;
__p += '(' +
((__t = ( L10N.get('trains.moscowTime') )) == null ? '' : __t) +
' - ' +
((__t = ( dateDeparture.toShortTime() )) == null ? '' : __t) +
' ' +
((__t = ( dateDeparture.toShortDate() )) == null ? '' : __t) +
') ';
 } ;
__p += '</span>\n                <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('transfers.arrivalDate') )) == null ? '' : __t) +
': <span data-replace-newline=" " data-copy-content-from=".b-route__arrival .b-route__date"></span> ' +
((__t = ( L10N.get('trains.localTimeInfo') )) == null ? '' : __t) +
' ';
 if (segments[0].arrivalMoscowTimeZone === true) { ;
__p += '(' +
((__t = ( L10N.get('trains.moscowTime') )) == null ? '' : __t) +
' - ' +
((__t = ( dateArrival.toShortTime() )) == null ? '' : __t) +
' ' +
((__t = ( dateArrival.toShortDate() )) == null ? '' : __t) +
') ';
 } ;
__p += '</span>\n                <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('filters.travelTime') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-route__info-duration"></span></span>\n                <span class="js-copy-content copy-content" data-replace-newline=" ">' +
((__t = ( L10N.get('flightlegs.classOfService') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-train__carriage-service-class-caption"></span> <span data-copy-title-from=".b-train__carriage-service-class-caption"></span></span>\n				<div class="b-offers-train-modal__list-item-drag "></div>\n				<div class="b-offers-train-modal__list-item-legs">\n					<div class="b-train-ticket">\n						<div class="b-train-ticket__header">\n							<div class="b-train-ticket__info-train b-info-train">\n								<span class="b-info-train__element">\n                                    <span class="b-info-train__number">\n									№ ' +
((__t = ( train.number )) == null ? '' : __t) +
'' +
((__t = ( !train.brand && train.category ? ',' : '' )) == null ? '' : __t) +
'\n								</span>\n                                    ';
 if (train.brand != null) { ;
__p += '\n                                        &mdash;\n                                        <span class="b-info-train__brand">\n										' +
((__t = ( train.brand )) == null ? '' : __t) +
'' +
((__t = ( train.category ? ',' : '' )) == null ? '' : __t) +
'\n									</span>\n                                    ';
 } ;
__p += '\n                                </span>\n								<span class="b-info-train__element">\n                                    ';
 if (train.category) { ;
__p += '\n                                        <span class="b-info-train__description">' +
((__t = ( train.category )) == null ? '' : __t) +
'</span>\n                                    ';
 } ;
__p += '\n                                </span>\n							</div>\n							';
 if (train.disabled === true) { ;
__p += '\n								<div class="b-train-ticket__info-disabled">\n									<span class="b-train-ticket__info-disabled-icon"\n										data-toggle="tooltip" data-placement="bottom"\n										title="' +
((__t = ( STATE.escape(train.disableReason) )) == null ? '' : __t) +
'"></span>\n								</div>\n							';
 } ;
__p += '\n						</div>\n                        <div class="b-train-ticket__mobile desktop-hide">\n                            <div class="b-train-ticket__mobile-dates">\n					            <span class="b-train-ticket__mobile-date-time">\n					            	' +
((__t = ( dateDepartureLocal.toShortTime() )) == null ? '' : __t) +
'\n					            </span>\n                                <span class="b-train-ticket__mobile-duration">' +
((__t = ( Formatter.formatDurationFromPattern(totalDurationInMinutes) )) == null ? '' : __t) +
'</span>\n                                <span class="b-train-ticket__mobile-date-time">\n						            ' +
((__t = ( dateArrivalLocal.toShortTime() )) == null ? '' : __t) +
'\n					            </span>\n                            </div>\n                            <div class="b-train-ticket__mobile-route-line"></div>\n                            <div class="b-train-ticket__mobile-route-info">\n                                <span class="b-train-ticket__mobile-route-location">\n                                    ';
 if (segments[0] && segments[0].departureLocation) { ;
__p += '\n                                        ' +
((__t = ( segments[0].departureLocation.caption.trim() )) == null ? '' : __t) +
'\n                                    ';
 } ;
__p += '\n                                </span>\n                                <span class="b-train-ticket__mobile-route-location">\n                                    ';
 if (segments[0] && segments[0].arrivalLocation) { ;
__p += '\n                                        ' +
((__t = ( segments[0].arrivalLocation.caption.trim() )) == null ? '' : __t) +
'\n                                    ';
 } ;
__p += '\n                                </span>\n                            </div>\n                        </div>\n						<div class="b-train-ticket__route b-route">\n							';

								var dateDepartureTooltip = "<div class='text-align-center' style='white-space: pre'>" + L10N.get('trains.cardLocalInfo');
								if (segments[0].departureMoscowTimeZone === true) {
									dateDepartureTooltip += '<br />' + L10N.get('trains.moscowTime') + ' - <strong>' + dateDeparture.toShortTime() + ' ' + dateDeparture.toShortDate() + '</strong>';
								}
								dateDepartureTooltip += '</div>';

								var dateArrivalTooltip = "<div class='text-align-center' style='white-space: pre'>" + L10N.get('trains.cardLocalInfo');
								if (segments[0].arrivalMoscowTimeZone === true) {
									dateArrivalTooltip += '<br />' + L10N.get('trains.moscowTime') + ' - <strong>' + dateArrival.toShortTime() + ' ' + dateArrival.toShortDate() + '</strong>';
								}
								dateArrivalTooltip += '</div>';
							;
__p += '\n							<div class="b-route__departure">\n								<div class="b-route__date" data-toggle="tooltip" data-html="true" data-placement="bottom" data-original-title="' +
((__t = ( dateDepartureTooltip )) == null ? '' : __t) +
'">\n									<div class="b-route__date-time">' +
((__t = ( (dateDepartureLocal || dateDeparture).toShortTime() )) == null ? '' : __t) +
'</div>\n									<div class="b-route__date-info">' +
((__t = ( (dateDepartureLocal || dateDeparture).toShortDate() )) == null ? '' : __t) +
'</div>\n								</div>\n								<div class="b-route__terminal">' +
((__t = ( segments[0].departureLocation.caption )) == null ? '' : __t) +
'</div>\n							</div>\n							<div class="b-route__info">\n								<div class="b-route__info-duration">' +
((__t = ( Formatter.formatDurationFromPattern(totalDurationInMinutes) )) == null ? '' : __t) +
'</div>\n								<div class="b-route__info-route">\n									<i class="icon-arrow-long"></i>\n								</div>\n							</div>\n							<div class="b-route__arrival">\n								<div>\n									<div class="b-route__date" data-toggle="tooltip" data-html="true" data-placement="bottom" data-original-title="' +
((__t = ( dateArrivalTooltip )) == null ? '' : __t) +
'">\n										<div class="b-route__date-time">' +
((__t = ( (dateArrivalLocal || dateArrival).toShortTime() )) == null ? '' : __t) +
'</div>\n										<div class="b-route__date-info">' +
((__t = ( (dateArrivalLocal || dateArrival).toShortDate() )) == null ? '' : __t) +
'</div>\n									</div>\n									';
 if (segments[0].carWithOtherArrivalTimeExists === true) { ;
__p += '\n										<div class="b-route__arrival-notification" data-toggle="tooltip" data-html="true" data-placement="bottom" data-original-title="<div class=\'text-align-center\'>' +
((__t = ( L10N.get('trains.carriageWithDiffTime') )) == null ? '' : __t) +
'</div>"></div>\n									';
 } ;
__p += '\n								</div>\n\n								<div class="b-route__terminal">' +
((__t = ( segments[0].arrivalLocation.caption )) == null ? '' : __t) +
'</div>\n							</div>\n\n							<div class="b-route__element b-route__element-seats">\n								' +
((__t = ( L10N.get('trains.carriage') )) == null ? '' : __t) +
': <strong class="b-route__element-seats-carriage-number">' +
((__t = ( carriage.number )) == null ? '' : __t) +
'</strong><br />\n								<div data-toggle="tooltip" data-html="true" data-original-title=\'' +
((__t = ( seatsMapTooltip || numbersString )) == null ? '' : __t) +
'\'>' +
((__t = ( numbersString )) == null ? '' : __t) +
'</div>\n							</div>\n              <div class="b-route__element b-route__element-seats-details" style="display: none;">' +
((__t = ( seatsMapForCopy )) == null ? '' : __t) +
'</div>\n\n							<div class="b-route__element">\n								<div class="b-train__carriage-service-class">\n									<span class="b-train__carriage-service-class-caption" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( STATE.escape(servicesTooltip || (carriage.serviceClass && carriage.serviceClass.description)) || '' )) == null ? '' : __t) +
'">\n										' +
((__t = ( carriage.serviceClass && carriage.serviceClass.caption ? carriage.serviceClass.caption : '-' )) == null ? '' : __t) +
'\n									</span>\n								</div>\n							</div>\n						</div>\n\n					</div>\n				</div>\n				<div class="b-offers-train-modal__list-item-price">\n					<div class="b-offers-train-modal__list-item-price-container">\n						<span class="b-offers-train-modal__list-item-price-amount">\n							' +
((__t = ( L10N.get('trains.from') )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatMoney(offer.totalPrice.amount, 0) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(offer.totalPrice.currencyCode) )) == null ? '' : __t) +
'\n						</span>\n						<span>\n							| ' +
((__t = ( L10N.get('trains.for') )) == null ? '' : __t) +
' <span class="b-offers-train-modal__title-person-count"></span> ' +
((__t = ( L10N.get('trains.person') )) == null ? '' : __t) +
'\n						</span>\n					</div>\n					<span class="b-offers-train-modal__list-item-remove"></span>\n				</div>\n			</div>\n			<div class="b-offers-train-modal__form b-offers-train-modal__form-control b-offers-train-modal__list-item-comment">\n				<span class="js-widget"  onclick=\'return new Widgets.Input({bindingProperty: "comment'+ i +'", placeholder: L10N.get("trains.comment") })\'></span>\n			</div>\n			<div class="b-offers-avia-modal__list-item-line">\n			</div>\n		';
 }); ;
__p += '\n	</div>\n\n	<div class="b-offers-train-modal__no-offers ' +
((__t = ( !_.isEmpty(this.offers) ? 'dn' : '' )) == null ? '' : __t) +
'">\n		<span>' +
((__t = ( L10N.get('trains.noOffers') )) == null ? '' : __t) +
'</span>\n	</div>\n\n	<div class="b-offers-train-modal__emails">\n\n	</div>\n\n    <div class="b-offers-train-modal__form">\n	';
 if (this.options != null && !_.isEmpty(this.options.order)) { ;
__p += '\n        <div class="b-message b-message-warning">\n            ' +
((__t = ( L10N.get('trains.sendOfferInfo') )) == null ? '' : __t) +
'\n        </div>\n        <div class="b-offers-train-modal__form-buttons">\n            <div class="b-offers-train-modal__form-button b-offers-train-modal__order">' +
((__t = ( L10N.get('trains.putToOrder') )) == null ? '' : __t) +
'</div>\n	';
 } else { ;
__p += '\n        <div class="b-offers-train-modal__form-buttons">\n            <div class="b-offers-train-modal__form-button b-offers-train-modal__submit">' +
((__t = ( L10N.get('trains.send') )) == null ? '' : __t) +
'</div>\n            <div class="b-offers-train-modal__form-button b-offers-train-modal__order">' +
((__t = ( L10N.get('trains.putToOrder') )) == null ? '' : __t) +
'</div>\n	';
 } ;
__p += '\n            <button class="b-offers-train-modal__form-button copy-btn js-copy-to-clipboard mobile-hide">' +
((__t = ( L10N.get('Common.copyToClipboard') )) == null ? '' : __t) +
'</button>\n        </div>\n    </div>\n\n	<div class="b-offers-train-modal__close"></div>\n</div>\n';

}
return __p
}