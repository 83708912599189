module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '    ';

	var step3Settings = STATE.getSettings().thirdStepSettings;
	var travelSubjectUid = travelSubjectUid || 'AIR';
    ;
__p += '\r\n	<div class="b-fare-rules__rules">\r\n		<div class="b-fare-rules__rules-content">\r\n            ';
 if (travelSubjectUid === 'AIR') { ;
__p += '\r\n            ' +
((__t = ( step3Settings.briefBookingRules )) == null ? '' : __t) +
'\r\n            ';
 } else if (travelSubjectUid === 'HOTEL') { ;
__p += '\r\n            ' +
((__t = ( step3Settings.briefBookingRulesHotels )) == null ? '' : __t) +
'\r\n            ';
 } else if (travelSubjectUid === 'RAIL') { ;
__p += '\r\n            ' +
((__t = ( step3Settings.briefBookingRulesRail )) == null ? '' : __t) +
'\r\n            ';
 } else { ;
__p += '\r\n            ' +
((__t = ( step3Settings.briefBookingRulesTransfers )) == null ? '' : __t) +
'\r\n            ';
 } ;
__p += '\r\n			<span class="b-fare-rules__ticketing-before"></span>\r\n			<div class="b-fare-rules__rules-list">\r\n				';

 				if (step3Settings.displayRulesInOneLine) {
					;
__p += '\r\n                    ';
 if (['HOTEL', 'TRANSFER'].includes(travelSubjectUid)) { ;
__p += '\r\n                        <div>\r\n                            <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "acceptAllRules", label:L10N.getUnescaped("bookingForm.allRulesTransfersAndHotels")})\'></span>\r\n                        </div>\r\n                    ';
 } else { ;
__p += '\r\n                        <div>\r\n                            <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "acceptAllRules", label:L10N.getUnescaped("bookingForm.allRules")})\'></span>\r\n                        </div>\r\n                    ';
 } ;
__p += '\r\n					';

				} else {
					;
__p += '\r\n                    ';
 if (['AIR', 'RAIL'].includes(travelSubjectUid)) { ;
__p += '\r\n					<div>\r\n						<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "acceptBookingRules", label:L10N.getUnescaped("bookingForm.bookingRules")})\'></span>\r\n					</div>\r\n					<div>\r\n						<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "acceptFareRemarks", label:L10N.getUnescaped("bookingForm.fareRules")})\'></span>\r\n					</div>\r\n                    ';
 } ;
__p += '\r\n                    ';
 if (['HOTEL', 'TRANSFER'].travelSubjectUid) { ;
__p += '\r\n                        <div>\r\n                            <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "acceptBookingRules", label:L10N.getUnescaped("bookingForm.bookingRules")})\'></span>\r\n                        </div>\r\n                    ';
 } ;
__p += '\r\n					';

					if (step3Settings.displayIataRules && travelSubjectUid === 'AIR') {
						;
__p += '\r\n						<div>\r\n							<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "acceptIataRules", label:L10N.getUnescaped("bookingForm.iataRules")})\'></span>\r\n						</div>\r\n						';

					}
				}
				;
__p += '\r\n				';
 if (step3Settings.displaySecurityRules) { ;
__p += '\r\n					<div>\r\n						<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "acceptSecurityRules", label:L10N.getUnescaped("bookingForm.acceptSecurityRules")})\'></span>\r\n					</div>\r\n				';
 } ;
__p += '\r\n			</div>\r\n		</div>\r\n    <div id="avia_rules_container"></div>\r\n	</div>\r\n';

}
return __p
}