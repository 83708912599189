module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-attachment-wrapper">\n	<div class="b-attachment-hotels">\n		<div class="b-attachment-hotels__wrapper">\n			<div class="b-search-form__params b-search-form__params-hotel">\n				<span class="b-hotels-custom-toggle">\n					<span class="js-widget"  onclick=\'return new Widgets.ToggleButton({bindingProperty: "additionalBed", label: L10N.get("hotels.additionalBed") })\'></span>\n				</span>\n        <span class="b-hotels-radius dn">\n          <span class="b-search-form__param-flight-number b-search-form__param-hotels-radius">\n            <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "radius", placeholder: "", label: null })\'></span>\n            <span class="b-radius-label">' +
((__t = ( L10N.get("hotels.radius") )) == null ? '' : __t) +
'</span>\n          </span>\n        </span>\n			</div>\n		</div>\n	</div>\n</div>\n';

}
return __p
}