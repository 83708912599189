// eslint-disable-next-line banned-modules
'use strict';

import Backbone from 'backbone';
import BaseModel from '@/classes/base.model';
import BaseCollection from '@/classes/base.collection';

import PassengersCollection
	from '@/blocks/pages/b-search-form/b-company-employees/b-employees/b-employees-collection/collection';
import RoomsCollection
	from '@/blocks/pages/b-search-form/b-company-employees/b-employees/b-rooms-collection/collection';

import PassengerAviaModel from '@/widgets/w-passengers/w-passengers-avia/passenger-model';
import PassengerRailModel from '@/widgets/w-passengers/w-passengers-rail/passenger-model';
import PassengerHotelModel from '@/widgets/w-passengers/w-passengers-hotel/passenger-model';
import PassengerTransferModel from '@/widgets/w-passengers/w-passengers-transfer/passenger-model';
import SearchRouteModel from '@/blocks/elements/b-search-route/model';
import SearchEmployeeModel
	from '@/blocks/pages/b-search-form/b-company-employees/b-employees/b-employees-collection/b-employee/model';
import SearchRoomModel
	from '@/blocks/pages/b-search-form/b-company-employees/b-employees/b-rooms-collection/b-room/model';

export default BaseModel.extend({

	defaults: () => {
		return {
			classOfService: 'ECONOMY',
			direct: false,
			routes: [],
			subAddresses: new BaseCollection([], {
				model: BaseModel,
			}),
			passengersTypes: {},
			routeType: 'ONEWAY',
			serviceTypes: new BaseCollection({
				uid: 'AVIA',
				caption: 'AVIA',
			}),
			changed: false,
			passengers: new PassengersCollection([], {
				model: SearchEmployeeModel,
			}),
			rooms: new RoomsCollection([], {
				model: SearchRoomModel,
			}),
			hotelsSearchSettings: new BaseModel(),
		};
	},

	initialize(attrs, options) {
		if (attrs.hotelsSearchSettings != null && !(attrs.hotelsSearchSettings instanceof BaseModel)) {
			this.set('hotelsSearchSettings', new BaseModel(attrs.hotelsSearchSettings));
		}
		if (attrs.serviceTypes != null) {
			if (_.find(attrs.serviceTypes, (s) => s.uid === 'AVIA') != null) {
				this.set('passengersTypes', new PassengerAviaModel(attrs.passengersTypes));
			} else if (_.find(attrs.serviceTypes, (s) => s.uid === 'RAIL') != null) {
				this.set('passengersTypes', new PassengerRailModel(attrs.passengersTypes));
			} else if (_.find(attrs.serviceTypes, (s) => s.uid === 'HOTEL') != null) {
				this.set('passengersTypes', new PassengerHotelModel(attrs.passengersTypes));
			} else if (_.find(attrs.serviceTypes, (s) => s.uid === 'TRANSFER') != null) {
				this.set('passengersTypes', new PassengerTransferModel(attrs.passengersTypes));
			}
		} else {
			this.set('passengersTypes', new PassengerAviaModel());
		}
		this.__isComplex = false;
		if (!attrs || !attrs.classOfService) {
			const {
				defaultDepartureLocation,
				defaultHotelDepartureLocation,
				defaultDepartureDateDelta,
			} = options.settings && options.settings.firstStepSettings || {};

			this.addRoute(new SearchRouteModel({
				departure: defaultDepartureLocation,
				dateTo: new Time().add(defaultDepartureDateDelta || 0, 'days').toServerDate(),
			}));

			this.set('city', defaultHotelDepartureLocation ? _.extend(defaultHotelDepartureLocation, {dataset: 'locations'}) : null);
		} else {
			const legs = this.get('routes');
			this.set('routes', []);
			legs.forEach(item => {
				this.addRoute(new SearchRouteModel(item));
			});

			this.set('passengers', new PassengersCollection(this.get('passengers'), {
				model: SearchEmployeeModel,
			}));

			if (_.find(attrs.serviceTypes, (s) => s.uid === 'HOTEL') != null) {
				this.set('rooms', new RoomsCollection(this.get('rooms'), {
					model: SearchRoomModel,
				}));
			}
		}
		if (!(this.get('serviceTypes') instanceof BaseCollection)) {
			this.set('serviceTypes', new BaseCollection(this.get('serviceTypes')));
		}

		if (!(this.get('subAddresses') instanceof BaseCollection)) {
			this.set('subAddresses', new BaseCollection([], {model: BaseModel}));
		}

		// JIRA IBECORP-3293
		if (!(this.get('rooms') instanceof Backbone.Collection)) {
			this.set('rooms', new RoomsCollection(attrs.rooms || [], {
				model: SearchRoomModel,
			}));
		}
		this.set('b2cSettings', options.b2cSettings);
	},

	toJSON() {
		const result = {
			parameters: BaseModel.prototype.toJSON.call(this),
		};
		const type = result.parameters.routeType;
		const {isB2C, client} = result.parameters.b2cSettings;
		delete result.parameters.isB2C;
		delete result.parameters.b2cSettings;

		result.parameters.routes = result.parameters.routes.map(item => item.toJSON());
		let serviceType = 'COMBINED';
		if (result.parameters.serviceTypes != null && result.parameters.serviceTypes.length === 1) {
			serviceType = result.parameters.serviceTypes[0].uid;
		}
		// delete result.parameters.flightNumber; // UTS-338
		delete result.parameters.serviceTypes;
		result.parameters.searchType = serviceType;

		if (!_.isEmpty(result.parameters.booking)) {
			result.parameters.booking = result.parameters.booking.bookingUid;
		}

		delete result.parameters.isComplex;
		if (this.isComplex()) {
			delete result.parameters.direct;
			_.each(result.parameters.routes, (r) => {
				r.date = r.dateTo;
				delete r.departureTimeWindowEnd;
				delete r.departureTimeWindowStart;
			});
		}

		delete result.parameters.singlePersonSearch;

		if (type === 'ONEWAY') {
			const leg = result.parameters.routes[0];
			leg.date = leg.dateTo;
			delete leg.dateTo;
			delete leg.dateFrom;
		} else if (type === 'ROUNDTRIP') {
			const leg = result.parameters.routes[0];
			const legBack = Object.assign({}, leg);

			legBack.time = legBack.timeTo;
			delete legBack.timeTo;

			result.parameters.routes.push(legBack);
			leg.date = leg.dateTo;
			legBack.date = leg.dateFrom;
			delete leg.dateTo;
			delete leg.dateFrom;
			delete legBack.dateTo;
			delete legBack.dateFrom;
			legBack.departure = leg.arrival;
			legBack.arrival = leg.departure;
		} else {
			const leg = result.parameters.routes[0];
			delete leg.dateTo;
			delete leg.dateFrom;
		}

		result.parameters.routes.forEach(item => {
			if (item.departure && item.departure.uid) {
				item.departureCode = item.departure.uid;
			} else {
				item.departureCode = '';
			}
			if (item.arrival && item.arrival.uid) {
				item.arrivalCode = item.arrival.uid;
			} else {
				item.arrivalCode = '';
			}
			if (item.flightNumber === '') {
				delete item.flightNumber;
			}
			if (item.time != null && item.time !== '') {
				const times = item.time.split(' – ');
				const timeFrom = new Time(times[0], 'HH : mm');
				const timeTo = new Time(times[1], 'HH : mm');

				// IBECORP-2020
				if (timeTo.moment.minutes() === 0 && timeTo.moment.hours() === 0) {
					timeTo.set({hour: 23, minute: 59});
				}

				item.departureTimeWindowStart = timeFrom.format('HH:mm:ss');
				item.departureTimeWindowEnd = timeTo.format('HH:mm:ss');
				delete item.time;
				delete item.timeTo;
			}
		});

		if (isB2C) result.parameters.client = client;

		const hotelFields = ['city', 'cityCode', 'roomsCount', 'checkInDate', 'checkOutDate', 'restHotelToken', 
			'hotelCardNumber', 'rooms', 'radius', 'latitude', 'longitude'];
		const transferFields = ['startPlace', 'endPlace', 'date', 'backDate', 'subAddresses', 'transferType', 'endRentDate'];
		if (serviceType === 'HOTEL') {
			const fileds = ['client', 'childsAge', 'searchType', 'passengersTypes', 'booking', ...hotelFields];
			
			result.parameters = _.pick(result.parameters, fileds);

			if (_.isEmpty(result.parameters.rooms) && !_.isEmpty(result.parameters.passengersTypes)) {
				result.parameters.rooms = [];

				const roomsCount = parseInt(result.parameters.roomsCount, 10);
				for (let i = 0; i < (_.isNaN(roomsCount) ? 1 : roomsCount); i++) {
					const item = {
						travellersInfo: result.parameters.passengersTypes,
					};

					if (!_.isEmpty(result.parameters.childsAge)) {
						item.childrenAge = result.parameters.childsAge;
					}

					result.parameters.rooms.push(item);
				}
			}

			const hotelsSearchSettings = this.get('hotelsSearchSettings');

			const isHotelSearchByRadiusEnabled = hotelsSearchSettings && hotelsSearchSettings.get ? 
				!!hotelsSearchSettings.get('hotelSearchByRadiusEnabled') : false;
      
			if (isHotelSearchByRadiusEnabled) {
				if (result.parameters.city) {
					const hotelsRadius = this.get('radius');
					result.parameters.latitude = result.parameters.city.latitude;
					result.parameters.longitude = result.parameters.city.longitude;
					result.parameters.radius = hotelsRadius || hotelsSearchSettings.get('hotelSearchDefaultRadius');
				} else {
					delete result.parameters.latitude;
					delete result.parameters.longitude;
					delete result.parameters.radius;
				}
			} else {
				delete result.parameters.latitude;
				delete result.parameters.longitude;
				delete result.parameters.radius;
			}
      
			if (result.parameters.cityCode && !result.parameters.city) {
				delete result.parameters.city;
			}

			if (result.parameters.hotelCardNumber != null) {
				const hotelInfo = {
					hotelCardNumber: result.parameters.hotelCardNumber.number,
					hotelCity: result.parameters.hotelCardNumber.city,
					hotelCountry: result.parameters.hotelCardNumber.country,
				};
				result.parameters.hotelInfo = hotelInfo;
				delete result.parameters.city;
				delete result.parameters.hotelCardNumber;
			} else if (result.parameters.city != null) {
				result.parameters.cityCode = result.parameters.city.code || result.parameters.city.uid;
				delete result.parameters.city;
				delete result.parameters.hotelCardNumber;
			}

			delete result.parameters.childsAge;
			delete result.parameters.passengersTypes;
			delete result.parameters.roomsCount;
		} else if (serviceType === 'TRANSFER') {
			const fields = ['searchType', 'passengers', 'booking', 'client', 'passengersTypes', ...transferFields];
			result.parameters.subAddresses = _.map(result.parameters.subAddresses, (item) => {
				const res = _.pick(item.location, ['longitude', 'latitude', 'address']);
				if (res.longitude) res.longitude = res.longitude.toString();
				if (res.latitude) res.latitude = res.latitude.toString();
				return res;
			});
			result.parameters.travellers = result.parameters.passengers;

			_.each(['startPlace', 'endPlace'], (key) => {
				if (!result.parameters[key]) return;
				const item = _.pick(result.parameters[key], ['longitude', 'latitude', 'address']);
				if (item.longitude) item.longitude = item.longitude.toString();
				if (item.latitude) item.latitude = item.latitude.toString();
				result.parameters[key] = item;
			});

			const {time, timeTo, date, backDate, transferType} = result.parameters;
			if (transferType && transferType.uid === 'RENT') {
				result.parameters.date =
					date != null ? `${date}${time != null ? `T${time.replace(/\s/g, '')}` : ''}` : null;

				result.parameters.endRentDate =
					backDate != null ? `${backDate}${timeTo != null ? `T${timeTo.replace(/\s/g, '')}` : ''}` : null;
				delete result.parameters.backDate;
				delete result.parameters.endPlace;
				delete result.parameters.subAddresses;
			} else {
				result.parameters.date = date && date.indexOf('T') === -1 ? `${date}T00:00:00` : date || null;
				if (backDate) result.parameters.backDate += backDate.indexOf('T') === -1 ? 'T00:00:00' : '';
			}

			result.parameters = _.pick(result.parameters, fields);
		} else {
			result.parameters = _.omit(result.parameters, _.flatten([hotelFields, transferFields]));
		}

		return result;
	},

	swapLocations(routeCid = this.get('routes')[0].cid) {
		if (this.isTransfer()) {
			const startPlace = this.get('startPlace');
			this.set('startPlace', this.get('endPlace'));
			this.set('endPlace', startPlace);
			return;
		}
		const leg = this.get('routes').find(el => el.cid === routeCid);
		const temp = leg.get('departure');
		leg.set('departure', leg.get('arrival'));
		leg.set('arrival', temp);
	},

	swapSiblingSubAddresses(cids) {
		const addresses = _.filter(this.get('subAddresses').models, (m) => cids.includes(m.cid));
		if (!_.isEmpty(addresses)) {
			const firstAddress = addresses[0];
			let temp;
			if (addresses.length > 1) {
				temp = firstAddress.get('location');
				firstAddress.set('location', addresses[1].get('location'));
				addresses[1].set('location', temp);
			} else {
				temp = this.get('endPlace');
				this.set('endPlace', firstAddress.get('location'));
				firstAddress.set('location', temp);
			}
		}
	},

	addAddress(address) {
		this.get('subAddresses').add(address);
		this.trigger('change:subAddresses', address);
	},

	addRoute(route) {
		this.get('routes').push(route);
		this.trigger('change:routes', route);
		route.on('destroy', () => {
			this.set('routes', this.get('routes').filter(item => {
				return item.cid !== route.cid;
			}));
			if (this.get('routes').length < 2) {
				// this.changeType('ONEWAY'); IBECORP-6306
				// this.set('isComplex', false);
			} else {
				this.trigger('change:routes', route);
			}
		});
		route.on('change:dateFrom', (model, value) => {
			if (value) {
				if (this.get('routeType') === 'ONEWAY') {
					this.changeType('ROUNDTRIP');
				}
			} else {
				if (this.get('routeType') !== 'ONEWAY') {
					this.changeType('ONEWAY');
				}
			}
		});
	},

	changeType(type) {
		const firstLeg = _.first(this.get('routes'));
		const lastLeg = _.last(this.get('routes'));

		this.set('routes', [firstLeg]);
		this.set('routeType', type);
		switch (type) {
			case 'ONEWAY':
				this.set('isComplex', false);
				this.trigger('change:routes', this.get('routes'));
				return;
			case 'ROUNDTRIP':
				this.set('isComplex', false);
				this.trigger('change:routes', this.get('routes'));
				return;
			case 'MULTISTOP':
				this.addRoute(new SearchRouteModel({
					departure: lastLeg.get('arrival'),
				}));
		}
	},

	addComplexRoute() {
		if (this.isComplex()) {
			const route = _.last(this.get('routes'));
			this.addRoute(new SearchRouteModel({
				departure: route.get('arrival'),
			}));
		}
	},

	isComplex() {
		return this.get('routeType') === 'MULTISTOP';
	},

	isTransfer() {
		return this.get('serviceTypes').find({uid: 'TRANSFER'}) != null;
	},

	handleValidation(...args) {
		if (_.isEmpty(args)) return;
		/* special case for round trip */
		const isRoundTrip = this.get('routeType') && this.get('routes').length === 1;

		if (this.isTransfer() && args[0].date) {
			args[0].time = args[0].date;
		}

		if (args[0].endRentDate) {
			args[0].backDate = args[0].endRentDate;
			args[0].timeTo = args[0].endRentDate;
		}

		if (args[0].hotelInfo || args[0].cityCode) {
			args[0].city = args[0].cityCode || args[0].hotelInfo;
		}

		if (args[0].routes) {
			_.each(args[0].routes, (value, i) => {
				if (value.arrivalCode != null) {
					value.arrival = value.arrivalCode;
					delete value.arrivalCode;
				}

				if (value.departureCode != null) {
					value.departure = value.departureCode;
					delete value.departureCode;
				}

				if (value.departureTimeWindowStart != null || value.departureTimeWindowEnd != null) {
					if (args[0].routes[0] == null) {
						args[0].routes[0] = {};
					}

					const key = (isRoundTrip && parseInt(i, 10) !== 0) ? 'timeTo' : 'time';
					args[0].routes[0][key] = value.departureTimeWindowStart || value.departureTimeWindowEnd;
					delete value.departureTimeWindowStart;
					delete value.departureTimeWindowEnd;
				}

				if (value.date != null && !this.isComplex()) {
					if (args[0].routes[0] == null) {
						args[0].routes[0] = {};
					}

					const key = (isRoundTrip && parseInt(i, 10) !== 0) ? 'dateFrom' : 'dateTo';
					args[0].routes[0][key] = value.date;
					delete value.date;
				} else if (value.date != null) {
					value.dateTo = value.date;
					delete value.date;
				}
			});
		} else if (args[0].flightLegs != null && isRoundTrip) {
			Object.keys(args[0].flightLegs).forEach(key => {
				if (key === '1' && args[0].flightLegs[key] != null) {
					const keys = args[0].flightLegs[key];
					Object.keys(keys).forEach(subKey => {
						if (subKey === 'date') {
							if (!args[0].flightLegs[0]) {
								args[0].flightLegs[0] = {};
								args[0].flightLegs[0].dateFrom = keys[subKey];
							}
							delete keys[subKey];
							if (Object.keys(keys).length === 0) {
								delete args[0].flightLegs[key];
							}
						}
					});
				}
			});
		}

		BaseModel.prototype.handleValidation.apply(this, args);
	},
});
