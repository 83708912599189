// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import BaseModel from '@/classes/base.model';
import template from './template.ejs';
import axios from 'axios';

export default BaseView.extend({

	el: '.b-attachment',

	template,

	initialize() {
		this.model = this.options.model;
		this.getHotelsFormSettings();
		this.listenTo(this.model, 'change:client', () => {
			this.getHotelsFormSettings();
		});
		this.render();
	},

	getHotelsFormSettings() {
		const parameters = {
			client: this.model.get('client'),
		};
		axios
			.post('/midoffice/ibecorp-b2b/hotels/formSettings', {
				parameters,
			})
			.then((response) => {
				this.model.set('hotelsSearchSettings', new BaseModel(response.data.result || {})).trigger('change');
				const hotelsSearchSettings = this.model.get('hotelsSearchSettings');
				const hotelSearchByRadiusEnabled = hotelsSearchSettings.get('hotelSearchByRadiusEnabled');
				const hotelSearchDefaultRadius = hotelsSearchSettings.get('hotelSearchDefaultRadius');
				if (_.isEmpty(this.model.get('radius')) && hotelSearchByRadiusEnabled) {
					this.model.set('radius', hotelSearchDefaultRadius);
				}
			});
	},

});
