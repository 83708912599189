/* eslint-disable no-case-declarations */
// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import template from './template.ejs';

import BaseView from '@/classes/base.view';
import BaseCollection from '@/classes/base.collection';
import SearchRoute from '@/blocks/elements/b-search-route/index';
import SearchRouteModel from '@/blocks/elements/b-search-route/model';
import TransferAddressModel from '@/blocks/elements/b-search-route/b-transfer-address/model';
import TransferAddress from '@/blocks/elements/b-search-route/b-transfer-address/index';

import AviaAttachment from './b-attachment-avia/index';
import RailwayAttachment from './b-attachment-railway/index';
import HotelsAttachment from './b-attachment-hotels/index';
import TransfersAttachment from './b-attachment-transfers/index';

import PassengerModelAvia from '@/widgets/w-passengers/w-passengers-avia/passenger-model';
import PassengerModelRail from '@/widgets/w-passengers/w-passengers-rail/passenger-model';
import PassengerModelHotel from '@/widgets/w-passengers/w-passengers-hotel/passenger-model';
import PassengerModelTransfer from '@/widgets/w-passengers/w-passengers-transfer/passenger-model';

import CompanyEmployeesView from './b-company-employees/index';
import AdditionalOrdersView from '@/blocks/elements/b-additional-orders/index';

import PikadayModalView from './b-pikaday-mobile-modal/index';

import axios from 'axios';
import $ from 'jquery';
import GlUl from '@/utils/global-utils';
import BaseModel from '../../../classes/base.model';

export default BaseView.extend({
	template,

	attachmentViews: {},

	events: {
		'click .p-search-form__submit': 'search',
		'click .p-search-form__add': 'addFlight',
		'click .p-search-form__additional-orders-title': 'toggleAdditionalOrders',
		'click .p-search-form__additional-orders-expand': 'toggleAdditionalOrders',
		'click .back-to-order-link': 'navigateBackToOrder',
		'click .b-search-route-address__collection__add': 'addAddress',
		'click .p-search__direction-date': 'showPikadayModal',
		'click .p-search__direction-date-calendar': 'showPikadayModal',
	},

	ui: {
		companyEmployees: '.b-company-employees',
		additionalOrders: '.p-search-form__additional-orders-container',
	},

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			mediaQuery: '(max-width: 768px)',
			name: 'change',
			callback: this.createPikadayModalOnChangeMedia.bind(this),
			isMatchMedia: true,
		});
	},

	initialize() {
		this.attachmentViews = {};
		this.model = this.options.model.off();
		this.pikadayViews = [];

		this.searchRails = this.searchRails.bind(this);
		this.searchHotels = this.searchHotels.bind(this);
		this.searchAvia = this.searchAvia.bind(this);
		this.searchTransfer = this.searchTransfer.bind(this);
		this.searchProcess = this.searchProcess.bind(this);

		this.addModelListeners();
		this.render();
		this.createRoutes();

		this.model.trigger('searchFormSettings', this.model);
		this.model.trigger('change', this.model);

		// Additional order from other order
		if (!_.isEmpty(this.options.params)) {
			const { params } = this.options;

			if (params.booking != null) {
				const travelSubject = {
					uid: params.travelSubject,
					caption: params.travelSubject,
				};

				const settings = STATE.getSettings();
				const passengersFromOrder = params.passengersFromOrder;
				const retailClient =
					(settings &&
						settings.corporateSettings &&
						settings.corporateSettings.retailClientReference) ||
					null;
				if (
					!_.isEmpty(passengersFromOrder) &&
					retailClient != null &&
					retailClient.uid === this.model.get('client').uid
				) {
					this.model.set('childsAge', passengersFromOrder.childsAge);
					let availableTypes;
					switch (params.travelSubject) {
						case 'AVIA':
							availableTypes = _.keys(PassengerModelRail.prototype.defaults());
							this.model.set(
								'passengersTypes',
								new PassengerModelAvia(
									_.pick(passengersFromOrder.travellersInfo, availableTypes),
								),
							);
							break;
						case 'HOTEL':
							availableTypes = _.keys(PassengerModelRail.prototype.defaults());
							this.model.set(
								'passengersTypes',
								new PassengerModelHotel(
									_.pick(passengersFromOrder.travellersInfo, availableTypes),
								),
							);
							break;
						case 'RAIL':
							availableTypes = _.keys(PassengerModelRail.prototype.defaults());
							this.model.set(
								'passengersTypes',
								new PassengerModelRail(
									_.pick(passengersFromOrder.travellersInfo, availableTypes),
								),
							);
							break;
						case 'TRANSFER':
							availableTypes = _.keys(
								PassengerModelTransfer.prototype.defaults(),
							);
							this.model.set(
								'passengersTypes',
								new PassengerModelTransfer(
									_.pick(passengersFromOrder.travellersInfo, availableTypes),
								),
							);
							break;
						default:
							break;
					}
				}

				this.model.set('booking', params.booking, { silent: true });
				_.defer(() => {
					let ts = travelSubject.uid.toLowerCase();
					if (ts === 'rail') {
						ts = 'train';
					}

					this.$el
						.find(`.service-type [data-service-type="${ts}"]`)
						.trigger('click');
					this.model.trigger(
						'change:booking',
						this.model,
						this.model.get('booking'),
					);
				});
			}
		} else if (this.model.has('booking') && this.model.get('booking') != null) {
			this.model.trigger(
				'change:booking',
				this.model,
				this.model.get('booking'),
			);
		}
		_.defer(() => this.getSelectedServiceTypeFromQueryVariable());
	},

	getSelectedServiceTypeFromQueryVariable() {
		const travelSubjectInQueryParam = STATE.getQueryVariable('ts');
		const availableServices = STATE.getSettings() && STATE.getSettings().corporateSettings && 
			STATE.getSettings().corporateSettings.availableServices || [];
		if (travelSubjectInQueryParam) {
			const service = availableServices.find(
				(s) => s.uid.toLowerCase() === travelSubjectInQueryParam.toLowerCase(),
			);
			logger.debug(
				`selected service from query variable is: ${service && service.uid}`,
			);
			if (service) {
				let tsFromService = service.uid.toLowerCase();
				if (tsFromService === 'rail') {
					tsFromService = 'train';
				}
				this.$el
					.find(`.service-type [data-service-type="${tsFromService}"]`)
					.click();
			}
		}
	},

	addModelListeners() {
		this.model.off();

		this.listenTo(this.model, 'scrollTop', this.scrollTopOnAddToOrder);
		this.listenTo(
			this.model,
			'change:booking',
			this.additionalOrderChange.bind(this),
		);
		this.listenTo(
			this.model,
			'change:routes change:subAddresses',
			this.createRoutes.bind(this),
		);
		this.listenTo(
			this.model,
			'convertRoutePoints',
			this.convertRoutePoints.bind(this),
		);
		this.listenTo(
			this.model.get('passengersTypes'),
			'change:passengersTypes',
			this.hidePassengersValidation.bind(this),
		);

		this.listenTo(this.model, 'change:serviceTypesRedraw', () => {
			this.model.changeType('ONEWAY');
			this.drawAttachments();
			this.model.trigger('change:updatePassengersByServiceType');
		});
		this.listenTo(this.model, 'change:client searchFormSettings', (model) => {
			if (
				_.some(
					this.model.get('serviceTypes').models,
					(el) => el.get('uid') === 'RAIL',
				)
			) {
				this.getSearchFormSettingsRail(model);
			}
		});
		this.listenTo(this.model, 'change:resetFormSettings', () => {
			this.model.get('routes').forEach((leg) => {
				leg.trigger('changeSettings:dateTo changeSettings:dateFrom', {
					maxDate: null,
				});
			});
		});
		this.listenTo(this.model, 'change', () => {
			let type = 'ONEWAY';
			if (this.model.isComplex()) {
				type = 'MULTISTOP';
			} else if (this.model.get('routes')[0].get('dateFrom') != null) {
				type = 'ROUNDTRIP';
			}

			if (type !== this.model.get('routeType')) {
				this.model.changeType(type);
			}
		});
		this.listenTo(this.model, 'change:routeType', (model, value) => {
			if (value === 'MULTISTOP') {
				model.set('preferredAirlines', []);
				model.set('flightNumber', null);
			} else {
				_.each(model.get('routes'), (m) => {
					m.set('flightNumber', null);
					m.set('airline', null);
				});
			}
		});
		this.listenTo(this.model, 'change:hotelsSearchSettings', () => {
			this.applyHotelsSearchSettings();
		});
		this.listenTo(this.model, 'change:city change:hotelCardNumber', (model) => {
			if (model.get('city')) {
				this.applyHotelsSearchSettings();
			}
		});
	},

	applyHotelsSearchSettings() {
		const isCitySearch = !!this.model.get('city');
		const hotelsSearchSettings = this.model.get('hotelsSearchSettings');
		if (hotelsSearchSettings && hotelsSearchSettings instanceof BaseModel) {
			const hotelSearchByRadiusEnabled = hotelsSearchSettings.get('hotelSearchByRadiusEnabled');
			if (hotelSearchByRadiusEnabled && isCitySearch) {
				this.$el.find('.b-hotels-radius').removeClass('dn');
			} else {
				this.$el.find('.b-hotels-radius').addClass('dn');
			}
		}
	},

	createPikadayModalOnChangeMedia(e) {
		if (!e.matches) {
			_.each(this.model.get('routes'), (route) => {
				this.createPikadayModalView(route);
			});
		}
		this.createRoutes();
	},

	delegateEvents(args) {
		BaseView.prototype.delegateEvents.apply(this, args);
	},

	undelegateEvents(args) {
		BaseView.prototype.undelegateEvents.apply(this, args);
	},

	render() {
		BaseView.prototype.render.call(this);

		const settings = STATE.getSettings();
		if (
			settings &&
			settings.corporateSettings != null &&
			settings.corporateSettings.showCorporateClientBlock === true
		) {
			const EMPLOYEES_VIEW = new CompanyEmployeesView({
				model: this.model,
				default: settings.corporateSettings.predefinedClientReference,
				retailClient: settings.corporateSettings.retailClientReference,
				corporateSettings: settings.corporateSettings,
			});

			this.ui.companyEmployees.html(EMPLOYEES_VIEW.$el);
		}
	},

	createPikadayModalView(route) {
		if (STATE.checkViewport('(max-width: 768px)')) {
			let travelSubject = { uid: 'AVIA', caption: 'AVIA' };
			if (!_.isEmpty(this.model.get('serviceTypes'))) travelSubject = this.model.get('serviceTypes').at(0).toJSON();
			let options = {
				travelSubject,
				model: route,
				cid: route.cid,
				searchModel: this.model,
			};

			if (['HOTEL', 'TRANSFER'].includes(travelSubject.uid)) {
				options = {
					travelSubject,
					model: this.model,
					cid: this.model.cid,
					searchModel: this.model,
				};
			}
			const view = new PikadayModalView(options);
			this.pikadayViews.push(view);
		}
	},

	clearPikadayModalViews() {
		_.each(this.pikadayViews, (v) => {
			v.destroy();
			$('[data-modal-id]').remove();
		});
	},

	showPikadayModal(e) {
		if (!STATE.checkViewport('(max-width: 768px)')) return;
		if (e != null) {
			if ($(e.currentTarget).hasClass('.b-datepicker__remove')) return;
			e.preventDefault();
			e.stopPropagation();
			const isShowDateTo =
				$(e.currentTarget).parents('.pikaday-modal_show-date-to').length > 0;
			const modalId =
				$(e.currentTarget)
					.closest('input[data-toggle-modal]')
					.attr('data-toggle-modal') ||
				$(e.currentTarget)
					.find('input[data-toggle-modal]')
					.attr('data-toggle-modal') ||
				$(e.currentTarget)
					.siblings('input[data-toggle-modal]')
					.attr('data-toggle-modal');
			const pikadayModal = _.find(
				this.pikadayViews,
				(v) => v.model.cid === modalId,
			);
			if (pikadayModal != null) {
				$('.p-search-form__wrapper').addClass('show-pikaday-modal');
				pikadayModal.show(isShowDateTo);
				if (!this.model.get('isComplex')) {
					if (isShowDateTo) {
						pikadayModal.dateTo.ui.input.focus();
					} else {
						pikadayModal.dateFrom.ui.input.focus();
					}
				}
			}
		}
	},

	convertRoutePoints(collection, value) {
		const currentServiceTypes = collection.toJSON();
		if (currentServiceTypes[0] == null) {
			return this;
		}

		const parameters = {
			currentRoutePointsType: currentServiceTypes[0].uid,
			neededRoutePointsType: value.uid,
		};

		const settings = STATE.getSettings();
		const {
			defaultDepartureLocation,
			defaultRailDepartureLocation,
			defaultHotelDepartureLocation,
		} = (settings && settings.firstStepSettings) || {};

		if (parameters.neededRoutePointsType === 'HOTEL') {
			if (this.model.get('city') == null) this.model.set('city', defaultHotelDepartureLocation);
			const passengersCollection = this.model.get('passengers');
			const roomsCollection = this.model.get('rooms');

			if (
				roomsCollection.length === 1 &&
				roomsCollection instanceof BaseCollection
			) {
				const travellersCollection = roomsCollection.at(0).get('travellers');

				if (travellersCollection.length <= 1) {
					travellersCollection.reset([]);
					_.each(passengersCollection.models, (p) => {
						const passengers = p.get('passengers');
						if (_.isEmpty(passengers)) {
							return;
						}

						travellersCollection.add(
							new travellersCollection.model(passengers),
						);
					});
				}
			}

			return this;
		}

		if (parameters.neededRoutePointsType === 'TRANSFER') return this;

		if (
			!this.needConversion(
				defaultDepartureLocation,
				defaultRailDepartureLocation,
				value.uid,
			)
		) return this;

		const defaultDeparture =
			parameters.neededRoutePointsType === 'AVIA'
				? defaultDepartureLocation
				: defaultRailDepartureLocation;

		if (['HOTEL', 'TRANSFER'].includes(parameters.currentRoutePointsType)) {
			parameters.currentRoutePointsType =
				parameters.neededRoutePointsType === 'AVIA' ? 'RAIL' : 'AVIA';
		}

		_.each(this.model.get('routes'), (model) => {
			const routes = _.map(
				[model.get('arrival'), model.get('departure')],
				(el) => (!_.isEmpty(el)
					? {
						code: el.uid || el.code,
						caption: el.caption,
					}
					: {}),
			);

			axios
				.post('/midoffice/ibecorp-b2b/autocomplete/convertRoutePoints', {
					parameters: _.extend(
						{
							routePoints: routes,
						},
						parameters,
					),
				})
				.then((response) => {
					if (response.data.result != null) {
						const { routePoints } = response.data.result;

						_.each(['arrival', 'departure'], (key, i) => {
							model.set(
								key,
								routePoints[i] != null
									? {
										uid: routePoints[i].code,
										caption: routePoints[i].caption,
									}
									: i === 1
										? defaultDeparture
										: {},
							);
						});
					}
				});
		});

		return this;
	},

	needConversion(defaultDepLoc, defaultDepLocRail, neededServiceType) {
		const routes = this.model.get('routes');
		if (routes.length === 1) {
			let result = true;
			const { departure, arrival } = routes.at(0).attributes;
			let routeDeparture = departure;

			if (_.isEmpty(departure)) {
				routeDeparture = defaultDepLoc;
			}
			if (!_.isEmpty(arrival)) return true;

			_.each([defaultDepLoc, defaultDepLocRail], (el) => {
				if (el && routeDeparture && el.uid === routeDeparture.uid) {
					result = false;
					if (neededServiceType === 'AVIA') routes.at(0).set('departure', defaultDepLoc);
					if (neededServiceType === 'RAIL') routes.at(0).set('departure', defaultDepLocRail);
				}
			});
			return result;
		}
		return true;
	},

	createRoutes() {
		if (!_.isEmpty(this.routeViews)) {
			_.each(this.routeViews, (view) => view.remove());
		}

		if (!_.isEmpty(this.addressViews)) {
			_.each(this.addressViews, (view) => view.remove());
		}
		this.clearPikadayModalViews();
		this.routeViews = [];
		this.addressViews = [];
		this.pikadayViews = [];

		const existServiceParams = this.$(
			'.p-search-form__element__service-params',
		).detach();
		this.$('.p-search-form__group__routes').append(existServiceParams);

		this.model.get('routes').forEach((route) => {
			route.off();

			const view = new SearchRoute({
				model: route,
				searchModel: this.model,
				parent: this,
			});
			this.routeViews.push(view);
			this.$('.p-search-form__element__routes').append(view.$el);
			this.createPikadayModalView(route);

			if (STATE.checkViewport('(min-width: 769px)')) {
				const existLocationsGroup = this.$(
					'.p-search-form__element__dates .p-search-form__element__dates-container',
				).detach();
				const locationsGroup = this.$(
					'.p-search-form__element__routes .p-search-form__element__dates-container',
				).detach();
				const existAdditionalOptionsGroup = this.$(
					'.p-search-form__element__additional-options .p-search-form__additional-options-container',
				);
				const additionalOptionsGroup = this.$(
					'.p-search-form__element__routes .p-search-form__additional-options-container',
				).detach();

				this.$('.p-search-form__element__additional-options').append(existAdditionalOptionsGroup);
				this.$('.p-search-form__element__additional-options').append(additionalOptionsGroup);
				this.$('.p-search-form__element__dates').append(existLocationsGroup);
				this.$('.p-search-form__element__dates').append(locationsGroup);
			}

			this.listenTo(
				route,
				'change:departure change:arrival',
				(model, value) => {
					this.model.trigger('searchFormSettings', model, value);
				},
			);

			this.listenTo(route, 'destroy', () => {
				this.model.set(
					'routes',
					this.model.get('routes').filter((item) => {
						return item.cid !== route.cid;
					}),
				);
				this.clearPikadayModalViews();
				if (this.model.get('routes').length < 2) {
					// this.model.changeType('ONEWAY'); IBECORP-6306
					// this.model.set('isComplex', false);
				} else {
					this.model.trigger('change:routes', route);
				}
			});

			this.listenTo(route, 'change:dateFrom', () => {
				let type = 'ONEWAY';
				if (this.model.isComplex()) {
					type = 'MULTISTOP';
				} else if (this.model.get('routes')[0].get('dateFrom') != null) {
					type = 'ROUNDTRIP';
				}
				if (type !== this.model.get('routeType')) {
					this.model.changeType(type);
				}
			});
		});

		if (this.model.get('serviceTypes').find({ uid: 'TRANSFER' }) != null) {
			if (STATE.checkViewport('(max-width: 768px)')) {
				this.$('.p-search-form__element__routes').append(existServiceParams);
			}

			_.each(this.model.get('subAddresses').models, (address) => {
				if (address != null) address.off();

				const view = new TransferAddress({
					model: address,
					searchModel: this.model,
					cid: address.cid,
				});
				this.addressViews.push(view);
				this.$('.b-search-route-address__collection').append(view.$el);

				this.listenTo(address, 'destroy', () => {
					this.model.get('subAddresses').reset(
						this.model.get('subAddresses').filter((item) => {
							return item.cid !== address.cid;
						}),
					);
					this.model.trigger('change:subAddresses');
					const transferTypeContent = this.$el
						.find('.b-search-route__transfer-type')
						.detach();
					this.$el
						.find('.p-search-form__transfer-row')
						.append(transferTypeContent);
				});
			});

			const existTransferTypeRow = this.$(
				'.b-search-route__transfer-type',
			).detach();
			this.$('.p-search-form__transfer-row').append(existTransferTypeRow);
		}

		if (this.model.get('routeType') === 'MULTISTOP') {
			this.$el.addClass('b-search-form__complex');
			this.$el.removeClass('b-search-form__one-way');
		} else if (this.model.get('routeType') === 'ONEWAY') {
			this.$el.addClass('b-search-form__one-way');
			this.$el.removeClass('b-search-form__complex');
		} else {
			this.$el.removeClass('b-search-form__one-way');
			this.$el.removeClass('b-search-form__complex');
		}
	},

	hidePassengersValidation() {
		const $container = this.$el
			.find('.b-passengers')
			.parent('.b-search-route__row');
		$container.find('.validation-error').removeClass('validation-error');
		$container.find('.validations-errors__container').remove();
	},

	addRoute() {
		if (this.model.get('routeType') === 'MULTISTOP') {
			this.model.addRoute(new SearchRouteModel());
		}
	},

	addAddress() {
		this.model.addAddress(new TransferAddressModel());
	},

	drawAttachments() {
		// TODO: remove after combined search will enabled
		const $icons = this.$(
			'.p-search-form__options-type-list .service-type:not(.disabled)',
		);
		$icons.removeClass('active');
		// EOF TODO

		const settings = STATE.getSettings();
		const availableServices = _.map(
			(settings &&
				settings.corporateSettings &&
				settings.corporateSettings.availableServices) ||
				[],
			(el) => el && el.uid,
		);
		const $serviceParams = this.$el.find(
			'.p-search-form__element__service-params',
		);
		const $withBorderRow = this.$el.find('.p-search-form__row-with-border');
		const $transferTypeRow = this.$el.find('.p-search-form__transfer-row');

		$withBorderRow.removeClass('empty-row');
		$serviceParams.html('');

		let notAvailibleService = false;
		_.each(this.model.get('serviceTypes').models, (m) => {
			if (!availableServices.includes(m.get('uid'))) {
				this.model.get('serviceTypes').remove(m);
			}

			if (this.model.get('serviceTypes').length === 0) {
				notAvailibleService = true;

				let serviceType = (availableServices[0] || '').toLowerCase();
				if (serviceType === 'rail') {
					serviceType = 'train';
				}

				this.$el.find('.b-attachment').html('');

				_.each(_.keys(this.attachmentViews), (key) => {
					this.attachmentViews[key].remove();
					delete this.attachmentViews[key];
				});

				_.defer(() => {
					$(`[data-service-type="${serviceType}"]`)
						.trigger('click')
						.parent('.service-type')
						.addClass('active');
				});
			}
		});

		if (notAvailibleService === true) {
			return this;
		}

		this.model.get('serviceTypes').forEach((m) => {
			if (!(m.get('uid') in this.attachmentViews)) {
				switch (m.get('uid')) {
					case 'AVIA':
						this.attachmentViews[m.get('uid')] = new AviaAttachment({
							model: this.model,
						});

						const paramComplex = this.$el
							.find('.b-search-form__param-complex')
							.detach();
						const paramClasses = this.$el
							.find('.b-search-form__param-classes')
							.detach();
						const paramDirect = this.$el
							.find('.b-search-form__param-direct')
							.detach();
						const paramMultiticket = this.$el
							.find('.b-search-form__param-multiticket')
							.detach();
						const paramBaglessFares = this.$el
							.find('.b-search-form__param-bagless-fares')
							.detach();
						const groupButton = this.$el
							.find('.b-search-form__param-buttons')
							.detach();

						const $serviceAviaParams = this.$el.find(
							'.b-attachment-avia__group-checkboxes',
						);

						paramComplex.appendTo($serviceParams);
						paramClasses.appendTo($serviceAviaParams.children()[0]);
						paramDirect.appendTo($serviceAviaParams.children()[0]);
						paramBaglessFares.appendTo($serviceAviaParams.children()[1]);
						paramMultiticket.appendTo($serviceAviaParams.children()[1]);
						groupButton.appendTo($serviceParams);

						$icons.filter('.service-type-avia').addClass('active');
						this.$el
							.removeClass('is-hotels-search is-rail-search is-transfer-search')
							.addClass('is-avia-search');
						this.$el
							.find('.p-search-form__row-footer .p-search-form__submit-button')
							.text(L10N.get('searchForm.searchButton'));
						break;
					case 'RAIL':
						this.attachmentViews[m.get('uid')] = new RailwayAttachment({
							model: this.model,
						});

						const railParams = this.$el
							.find('.b-attachment-rail .b-search-form__params')
							.detach();
						railParams.appendTo($serviceParams);
						$withBorderRow.addClass('empty-row');

						$icons.filter('.service-type-train').addClass('active');
						this.$el
							.removeClass('is-hotels-search is-avia-search')
							.addClass('is-rail-search');
						this.$el
							.find('.p-search-form__submit-button')
							.text(L10N.get('searchForm.searchButton'));
						break;
					case 'HOTEL':
						this.attachmentViews[m.get('uid')] = new HotelsAttachment({
							model: this.model,
						});

						const hotelsParams = this.$el
							.find('.b-attachment-hotels .b-search-form__params')
							.detach();
						hotelsParams.appendTo($serviceParams);

						$withBorderRow.addClass('empty-row');
						$icons.filter('.service-type-hotel').addClass('active');
						this.$el
							.removeClass('is-rail-search is-avia-search is-transfer-search')
							.addClass('is-hotels-search');
						this.$el
							.find('.p-search-form__submit-button')
							.text(L10N.get('searchForm.searchButton'));
						break;
					case 'TRANSFER':
						this.attachmentViews[m.get('uid')] = new TransfersAttachment({
							model: this.model,
							transferType: this.model.get('transferType'),
						});

						const transferTypeContent = this.$el
							.find('.b-search-route__transfer-type')
							.detach();
						const routesCollectionAdd = this.$el
							.find(`.b-search-route-address__collection__add`)
							.detach();
						transferTypeContent.appendTo($transferTypeRow);
						routesCollectionAdd.appendTo($serviceParams);

						if (this.model.get('transferType').uid === 'RENT') {
							routesCollectionAdd.hide();
						}

						$icons.filter('.service-type-transfer').addClass('active');
						this.$el
							.removeClass('is-rail-search is-avia-search is-hotels-search')
							.addClass('is-transfer-search');
						this.$el
							.find('.p-search-form__submit-button')
							.text(L10N.get('searchForm.searchButton'));
						break;
				}
			} else {
				this.attachmentViews[m.get('uid')].render();
			}
		});

		_.each(_.keys(this.attachmentViews), (viewUid) => {
			const testObj = {
				uid: viewUid,
			};

			if (!this.model.get('serviceTypes').find(testObj)) {
				this.attachmentViews[viewUid].remove();
				delete this.attachmentViews[viewUid];
			}
		});

		return this;
	},

	getSearchFormSettingsRail(model = this.model) {
		const legs = [];

		if (_.isEmpty(this.model.get('client'))) {
			return;
		}

		if (model.get('routes') != null) {
			legs.push(...model.get('routes'));
		} else {
			legs.push(model);
		}

		_.each(legs, (leg) => {
			const parameters = {
				client: this.model.get('client'),
			};

			if (!_.isEmpty(leg.get('departure')) && !_.isEmpty(leg.get('arrival'))) {
				parameters.departureLocation = leg.get('departure');
				parameters.arrivalLocation = leg.get('arrival');
			} else if (
				_.filter([leg.get('departure'), leg.get('arrival')], (el) => _.isEmpty(el),
				).length === 1
			) {
				// If not departure or arrival, but not at the same time
				return;
			}

			axios
				.post(
					'/midoffice/ibecorp-b2b/rail/searchFormSettings',
					{
						parameters,
					},
					this.model,
				)
				.then((response) => {
					const { result } = response.data;
					const limitDay = new Date();
					const limitDayBack = new Date();

					limitDay.setDate(
						limitDay.getDate() + parseInt(result.limitDays - 1, 10),
					);
					limitDayBack.setDate(
						limitDayBack.getDate() + parseInt(result.limitDaysBack - 1, 10),
					);

					leg.trigger('changeSettings:dateTo', {
						maxDate: limitDay,
					});
					leg.trigger('changeSettings:dateFrom', {
						maxDate: limitDayBack,
					});
				});
		});
	},

	additionalOrderChange(model, value) {
		const $container = this.$el.find('.p-search-form__additional-order');
		const $inputClient = this.$el.find(
			'.b-company-employees-service__wrapper .b-search-form__params .b-input__value',
		);
		const $inputClientRemove = this.$el.find(
			'.b-company-employees-service__wrapper .b-search-form__params .b-input__remove',
		);

		if (_.isObject(value)) {
			const { number, bookingUid } = value;
			const $element = $(
				'<i class="g-icon-w-cancel p-search-form__additional-order-delete"></i>',
			);

			$element.click(() => {
				this.model.unset('booking');
				$inputClient.removeAttr('disabled');
				$inputClientRemove.show();
				localStorage.removeItem('main_search');
			});

			$container
				.html($element)
				.append(
					`${L10N.get(
						'searchForm.inOrder',
					)}: <a class="back-to-order-link action-link--color-blue">№${number}</a>`,
				);

			if (
				this.model.get('booking') != null &&
				this.model.get('booking').autocompleted === true
			) {
				return this;
			}

			STATE.showLoader();

			$inputClient.removeAttr('disabled');
			const searchModel = this.model.toJSON();
			axios
				.post(
					'/midoffice/ibecorp-b2b/search/formData',
					{
						parameters: {
							searchType: searchModel.parameters.searchType,
							booking: {
								uid: bookingUid,
							},
						},
					},
					this.model,
				)
				.then((response) => {
					STATE.hideLoader();

					const { order } = response.data.result;
					const settings = STATE.getSettings();
					const retailClient =
						(settings &&
							settings.corporateSettings &&
							settings.corporateSettings.retailClientReference) ||
						null;

					this.model.set(
						'booking',
						_.extend({}, this.model.get('booking'), {
							autocompleted: true,
						}),
						{ silent: true },
					);

					if (order.client != null) {
						$inputClient.attr('disabled', 'disabled');
						$inputClientRemove.hide();

						if (
							this.model.get('client') == null ||
							this.model.get('client').uid !== order.client.uid
						) {
							this.model.set('client', order.client);
						}

						if (
							!_.isEmpty(order.passengers) &&
							(retailClient == null || retailClient.uid !== order.client.uid)
						) {
							const passengersCollection = this.model.get('passengers');
							const roomsCollection = this.model.get('rooms');

							_.defer(() => {
								const addedPassengers = [];
								passengersCollection.reset([]);

								// Hotels
								roomsCollection.reset([]);
								roomsCollection.add(new roomsCollection.model(null));
								const travellersCollection = roomsCollection
									.at(0)
									.get('travellers');
								travellersCollection.reset([]);
								_.each(order.passengers, (p) => {
									if (addedPassengers.includes(p.uid)) {
										return;
									}

									p = _.extend(
										{
											caption: L10N.get(`searchForm.nodata`),
										},
										p,
									);
									passengersCollection.add(new passengersCollection.model(p));
									travellersCollection.add(new travellersCollection.model(p));

									addedPassengers.push(p.uid);
								});
							});
						}
					}

					const {
						defaultRailDepartureLocation,
						defaultDepartureLocation,
						defaultHotelDepartureLocation,
						defaultDepartureDateDelta,
					} =
						(settings &&
							settings.firstStepSettings &&
							settings.firstStepSettings) ||
						{};

					if (!_.isEmpty(order.routes)) {
						const { routes } = order;

						// For hotels available params: city, hotelCardNumber, checkInDate, checkOutDate
						if (searchModel.parameters.searchType === 'HOTEL') {
							const route = _.last(routes);

							this.model.set(
								'city',
								_.extend(route.arrivalPoint, { dataset: 'locations' }),
							);
							this.model.set('checkInDate', route.date);
							this.model.set('checkOutDate', route.endDate);
						} else {
							let defaultDeparture = null;
							if (searchModel.parameters.searchType === 'RAIL') {
								defaultDeparture = defaultRailDepartureLocation || null;
							}
							if (searchModel.parameters.searchType === 'AVIA') {
								defaultDeparture = defaultDepartureLocation || null;
							}
							_.map(routes, (route) => {
								if (route.arrivalPoint != null) {
									route.arrivalPoint.uid = route.arrivalPoint.code;
									delete route.arrivalPoint.code;
								}

								if (route.departurePoint != null) {
									route.departurePoint.uid = route.departurePoint.code;
									delete route.departurePoint.code;
								}

								return route;
							});

							_.each(routes, (route, index) => {
								this.model.get('routes')[index].set({
									arrival: route.arrivalPoint,
									departure: route.departurePoint
										? route.departurePoint
										: defaultDeparture,
									dateTo: route.date,
								});
							});
						}
					} else {
						const dateTo = new Time()
							.add(defaultDepartureDateDelta || 0, 'days')
							.toServerDate();
						if (['AVIA', 'RAIL'].includes(searchModel.parameters.searchType)) {
							const departure =
								searchModel.parameters.searchType === 'AVIA'
									? defaultDepartureLocation
									: defaultRailDepartureLocation;
							this.model.get('routes').at(0).set({
								departure,
								dateTo,
							});
						}
						if (searchModel.parameters.searchType === 'HOTEL') {
							this.model.set(
								'city',
								defaultHotelDepartureLocation
									? _.extend(defaultHotelDepartureLocation, {
										dataset: 'locations',
									})
									: null,
							);
							this.model.set('checkInDate', dateTo);
						}
					}
				});
		} else {
			$container.empty();
		}
		return this;
	},

	getOrders() {
		STATE.showLoader();
		return axios
			.post('/midoffice/ibecorp-b2b/cabinetNew/getOrders', {
				parameters: {
					page: 0,
					pageSize: 10,
				},
			})
			.then((response) => {
				STATE.hideLoader();

				const { result } = response.data;
				if (result != null && _.size(result.orders) > 0) {
					this.orders = result.orders;
					this.ui.additionalOrders.append(
						new AdditionalOrdersView({
							parent: this,
							orders: this.orders,
							linkHash: STATE.ROUTES.CABINET_ORDER,
							model: this.model,
						}).$el,
					);
				}
			});
	},

	scrollTopOnAddToOrder() {
		$(window).scrollTop(0);
	},

	toggleAdditionalOrders(e) {
		if (e != null) {
			e.preventDefault();
		}

		const $target = $(e.target);
		const $container = $target.closest('.p-search-form__additional-orders');
		const $orders = this.ui.additionalOrders;

		const promise = _.isEmpty(this.orders)
			? this.getOrders()
			: Promise.resolve();

		if ($container.hasClass('is-open')) {
			$orders.stop().slideUp(400, () => {
				$container.removeClass('is-open');
			});
		} else {
			promise.then(() => {
				$container.addClass('is-open');
				$orders.stop().slideDown();
			});
		}
	},

	addFlight(e) {
		if (e instanceof $.Event) {
			e.preventDefault();
		}

		const $target = $(e.currentTarget);
		if (this.model.get('routes').length < 6) {
			this.model.addComplexRoute();
		}

		if (this.model.get('routes').length >= 6 && !$target.hasClass('disabled')) {
			$target
				.attr('data-origin-title', $target.text())
				.addClass('disabled')
				.children('a')
				.text(L10N.get('searchForm.maxFlights'));
		}
	},

	checkSearchParameters(parameters) {
		const handler = this.searchProcess;

		if (
			parameters &&
			parameters.parameters &&
			parameters.parameters.searchType === 'HOTEL' &&
      parameters.parameters.rooms
		) {
			const passengers = [];
			(parameters.parameters.rooms || []).forEach((room) => {
				if (room && room.travellers) {
					(room.travellers || []).forEach((traveller) => {
						if (traveller) {
							passengers.push(traveller);
						}
					});
				}
			});
			parameters.parameters.passengers = passengers;
		}

		return axios
			.post(
				'/midoffice/ibecorp-b2b/search/checkSearchParameters',
				parameters,
				this.model,
			)
			.then((response) => {
				const { result = {} } = response.data || {};
				const { message, abortProcess } = result;
				if (message) {
					let popup;
					const actions = [
						{
							label: L10N.get('bookingForm.dupesPopup.back'),
							action: () => {
								popup.hide();
							},
						},
						{
							label: L10N.get('bookingForm.dupesPopup.continue'),
							action: () => {
								STATE.showLoader();
								popup.hide();
								handler();
							},
						},
					];
					if (abortProcess) actions.pop();
					popup = new Widgets.Popup({
						title: L10N.get('bookingForm.attention'),
						content: message,
						type: 'info',
						actions,
					});
					popup.show();
					return false;
				}
				return true;
			});
	},

	searchProcess(skipOther = false) {
		switch (this.searchConfig.step) {
			case 1: // CHECK_PARAMS
				this.searchConfig.step++;
				if (STATE.checkSiteType('B2C') || skipOther) this.searchConfig.step = 3;
				this.checkSearchParameters(this.model.toJSON()).then(
					(res) => res && this.searchProcess(),
				);
				break;
			case 2: // GET_DUPES
				this.searchConfig.step++;
				if (STATE.checkSiteType('B2C') || skipOther) this.searchConfig.step = 3;
				this.getDupes(this.searchConfig.request).then((response) => {
					const { result } = response.data;
					if (!result) return this.searchProcess();

					return this.showDupesDialog(
						result.message,
						this.searchConfig.handler,
					);
				});
				break;
			case 3: // SEARCH_BY_SERVICE_TYPE
				return this.searchConfig.handler();
			default:
				return this.searchConfig.handler();
		}
		return this;
	},

	search(e) {
		if (e != null) {
			e.preventDefault();
		}
		STATE.showLoader();
		const model = this.model.toJSON();
		this.searchConfig = {
			step: 1,
		};

		this.disableElements(e);
		let firstTravelDate;
		let lastTravelDate;
		switch (model.parameters.searchType) {
			case 'RAIL':
				this.searchConfig.handler = this.searchRails;
				if (model.parameters.passengers.length < 1) return this.searchProcess(true);

				firstTravelDate = model.parameters.routes[0].date;
				lastTravelDate =
					model.parameters.routes[model.parameters.routes.length - 1].date;

				this.searchConfig.request = {
					parameters: {
						firstTravelDate,
						lastTravelDate,
						travellers: model.parameters.passengers,
					},
				};
				break;
			case 'HOTEL':
				this.searchConfig.handler = this.searchHotels;
				if (!model.parameters.rooms[0].travellers) return this.searchProcess(true);

				const travellers = _.reduce(
					_.map(model.parameters.rooms, (r) => r.travellers),
					(prev, curr) => prev.concat(curr),
				);

				this.searchConfig.request = {
					parameters: {
						firstTravelDate: model.parameters.checkInDate,
						lastTravelDate: model.parameters.checkOutDate,
						travellers,
					},
				};
				break;
			case 'AVIA':
				this.searchConfig.handler = this.searchAvia;
				if (
					model.parameters.passengers.length < 1 ||
					STATE.checkSiteType('B2C')
				) return this.searchProcess(true);
				firstTravelDate = model.parameters.routes[0].date;
				lastTravelDate =
					model.parameters.routes[model.parameters.routes.length - 1].date;

				this.searchConfig.request = {
					parameters: {
						firstTravelDate,
						lastTravelDate,
						travellers: model.parameters.passengers,
					},
				};
				break;
			case 'TRANSFER':
				this.searchConfig.handler = this.searchTransfer;
				if (
					model.parameters.passengers.length < 1 ||
					STATE.checkSiteType('B2C')
				) return this.searchProcess(true);

				firstTravelDate =
					model.parameters.date && model.parameters.date.split('T')[0];
				lastTravelDate =
					(model.parameters.backDate &&
						model.parameters.backDate.split('T')[0]) ||
					firstTravelDate;

				this.searchConfig.request = {
					parameters: {
						firstTravelDate,
						lastTravelDate,
						travellers: model.parameters.passengers,
					},
				};
				break;
			default:
				return this;
		}
		this.searchProcess();
		return this;
	},

	showDupesDialog(message) {
		const handler = this.searchProcess;
		const popup = new Widgets.Popup({
			title: L10N.get('bookingForm.dupesPopup.searchTitle'),
			content: message,
			type: 'info',
			actions: [
				{
					label: L10N.get('bookingForm.dupesPopup.back'),
					action: () => {
						popup.hide();
					},
				},
				{
					label: L10N.get('bookingForm.dupesPopup.continue'),
					action: () => {
						STATE.showLoader();
						popup.hide();
						handler(true);
					},
				},
			],
		});
		popup.show();
		_.each($(`.b-popup__content a`), (a) => $(a).on('click', () => popup.hide()),
		);

		return this;
	},

	searchHotels() {
		const model = this.model.toJSON();

		axios.get('/midoffice/ibecorp-b2b/hotels/searchPreferences').then((p) => {
			const preferences = p.data.result;

			axios
				.post('/midoffice/ibecorp-b2b/hotels/searchOffers', model, this.model)
				.then((result) => {
					this.showMessagePopup(result);
					if (this.options.isExternalForm) {
						this.model.trigger('sendExternalSearch');
						return;
					}

					STATE.setHotelsSearchResult(
						_.extend({}, result.data.result, preferences),
					);
					const searchModel = STATE.getSearchModel();
					STORE.set(
						STATE.ROUTES.MAIN_SEARCH,
						searchModel.attributes,
						14400,
					);
					STATE.setFormState('hotels:filter', null);
					STATE.navigate('hotels/offers');
					this.model.set('changed', false);
				});
		});
	},

	searchRails() {
		const model = this.model.toJSON();

		axios
			.post('/midoffice/ibecorp-b2b/search/getTransport', model, this.model)
			.then((result) => {
				if (this.options.isExternalForm) {
					this.model.trigger('sendExternalSearch');
					return;
				}
				STATE.setTrainsSearchResult(result.data.result);
				STORE.set(
					STATE.ROUTES.MAIN_SEARCH,
					STATE.getSearchModel().attributes,
					14400,
				);
				STATE.setFormState('trains:filter', null);
				STATE.navigate('trains/tickets/route/1');
				this.model.set('changed', false);
			});
	},

	searchAvia() {
		const model = this.model.toJSON();

		if (model.parameters.routeType !== 'MULTISTOP') {
			_.each(model.parameters.routes, (r, i) => {
				const par = model.parameters;
				if (par.time === '') {
					delete par.time;
				} else if (par.timeTo === '') {
					delete par.timeTo;
				}
				const getTime = (timeString, idx) => timeString?.replace(/\s/g, '').split('–')[idx]; 
				if (i === 1) {
					if (!_.isEmpty(par.airlineFrom)) {
						r.airline = par.airlineFrom;
					}
					if (!_.isEmpty(par.flightNumberFrom)) {
						r.flightNumber = par.flightNumberFrom;
					}
					if (!_.isEmpty(par.timeTo)) {
						r.departureTimeWindowStart = getTime(par.timeTo, 0);
						r.departureTimeWindowEnd = getTime(par.timeTo, 1);
					}
				} else {
					if (!_.isEmpty(par.airlineTo)) {
						r.airline = par.airlineTo;
					}
					if (!_.isEmpty(par.flightNumberTo)) {
						r.flightNumber = par.flightNumberTo;
					}
					if (!_.isEmpty(par.time)) {
						r.departureTimeWindowStart = getTime(par.time, 0);
						r.departureTimeWindowEnd = getTime(par.time, 1);
					}
				}				

				if (r.departureTimeWindowEnd === '00:00' || r.departureTimeWindowEnd === '24:00') {
					r.departureTimeWindowEnd = '23:59';
				}
			});
			if (model.parameters.validationErrors) {
				delete model.parameters.validationErrors;
			}
		}

		axios
			.post('/midoffice/ibecorp-b2b/search/getTransport', model, this.model)
			.then((result) => {
				this.showMessagePopup(result);
				if (this.options.isExternalForm) {
					this.model.trigger('sendExternalSearch');
					return;
				}
				STATE.setSearchResult(result.data.result);
				STORE.set(
					STATE.ROUTES.MAIN_SEARCH,
					STATE.getSearchModel().attributes,
					14400,
				);
				STATE.setFormState('avia:filter', null);
				STATE.navigate('avia/tickets');
				this.model.set('changed', false);
			}).catch((err) => {
				return this.model.set('validationErrors', err);
			});
	},

	searchTransfer() {
		const model = this.model.toJSON();

		if (GlUl.geocoderLocationUpdateNeeded(this.model)) return GlUl.updateGeocoderLocations(this.searchTransfer, this.model);

		return axios
			.post('/midoffice/ibecorp-b2b/transfers/search', model, this.model)
			.then((result) => {
				this.showMessagePopup(result);
				if (this.options.isExternalForm) {
					this.model.trigger('sendExternalSearch');
					return;
				}

				STATE.setTransfersSearchResult(result.data.result);
				const searchModel = STATE.getSearchModel();
				STORE.set(
					STATE.ROUTES.MAIN_SEARCH,
					searchModel.attributes,
					14400,
				);
				STATE.setFormState('transfers:filter', null);
				STATE.navigate('transfers/offers');
				this.model.set('changed', false);
			});
	},

	getDupes(request) {
		const bookingInfo = this.model.get('booking');
		if (bookingInfo) {
			request.parameters.booking = {
				uid: bookingInfo.bookingUid,
			};
		}
		return axios.post(
			'/midoffice/ibecorp-b2b/search/getDupes',
			request,
			this.model,
		);
	},

	showMessagePopup(result) {
		let messages;
		let subTitle = ``;

		if (
			result != null &&
			result.data != null &&
			result.data.messages != null &&
			result.data.messages.length > 0
		) {
			messages = this.concatMessages(result.data.messages);
		}

		if (
			result != null &&
			result.data != null &&
			result.data.result != null &&
			result.data.result.messages != null &&
			result.data.result.messages.length > 0
		) {
			messages = this.concatMessages(result.data.result.messages);
			subTitle = `${L10N.get('cabinet.menu.reportAgency')}<br />`;
		}

		const isWarning = _.some(
			result.data.messages,
			(m) => m.severity === 'WARNING',
		);

		if (messages) {
			const popup = new Widgets.Popup({
				content: isWarning
					? [L10N.get('hotels.noOffers'), messages].join('<br/><br/>')
					: subTitle + messages,
				closeOnlyOnAction: false,
				type: isWarning ? 'danger' : 'info',
				actions: [
					{
						label: isWarning
							? L10N.get('errors.noTicketsButton')
							: L10N.get('searchForm.close'),
						action: () => {
							popup.hide();

							if (isWarning) {
								STATE.navigate(STATE.ROUTES.INDEX);
							}
						},
					},
				],
				classes: 'b-reload-popup',
			});
			popup.show();
		}
	},

	concatMessages(messages) {
		return messages.reduce((prev, curr) => {
			let resultMessages = prev;
			resultMessages += `${curr.text || curr}<br/>`;
			return resultMessages;
		}, '');
	},

	navigateBackToOrder() {
		const bookingInfo = this.model.get('booking');
		if (bookingInfo && !_.isEmpty(bookingInfo) && bookingInfo.bookingUid) {
			STATE.navigate(
				`${STATE.ROUTES.CABINET_ORDER}/${this.model.get(`booking`).bookingUid}`,
			);
		}
	},
});
